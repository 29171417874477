import React, { useState } from "react"
import { Link as ScrollLink } from "react-scroll"
import Typography from "components/Typography"
import Audio from "components/Player/Audio"
import { FlexContainer, QuestionWrapper, QuestionMark, SingleQuestion, Button, AnswerWrapper, SingleAnswer, ImageWrapper, Img, } from "./styles"
import { isMobile } from "utils"

const SingleQuestionComponent = ({ question, content, image, alt, name, audio, id }) => {

    const [active, setActive] = useState < boolean > (false);

    const expand = () => {
        setActive((state) => !state);
    }

    const getFirstName = (name) => {
        const first = name.split(" ")[0];
        const second = name.split(" ")[1];
        if (first === "prof.") {
            return first + " " + second;
        } else {
            return first;
        }
    }

    const getLastName = (name) => {
        return name.slice(name.lastIndexOf(' ') + 1);
    }

    return (
        <FlexContainer>
            <QuestionWrapper data-id={id} id={id + '-question'}>
                <SingleQuestion>
                    <QuestionMark src="/questionmark.png" width="35" height="35" />
                    <Typography
                        variant="bodySection"
                        color="text.primary"
                        fontSize={isMobile ? "14px" : "16px"}
                        fontWeight={isMobile ? 400 : 500}>
                        {question}
                    </Typography>
                </SingleQuestion>
                {isMobile &&
                    <AnswerWrapper active={active}>
                        <SingleAnswer>
                            <ImageWrapper>
                                <Img src={image} alt={alt} />
                                <div>
                                    <Typography
                                        variant="questionsName"
                                        fontSize="23px"
                                        color="text.dark">
                                        {getFirstName(name)}
                                    </Typography>
                                    <Typography
                                        variant="questionsName"
                                        fontSize="36px"
                                        color="neutral.ultraLight"
                                        paddingBottom="20px">
                                        {getLastName(name)}
                                    </Typography>
                                </div>
                            </ImageWrapper>
                            {audio && (
                                <Audio track={audio} specialColor={"#23191C"} />
                            )}
                            <Typography
                                variant="bodySection"
                                color="text.primary"
                                fontSize={isMobile ? "14px" : "16px"}
                                fontWeight={isMobile ? 400 : 500}
                                dangerouslySetInnerHTML={{ __html: content }}>
                            </Typography>
                        </SingleAnswer>
                    </AnswerWrapper>
                }
                <ScrollLink
                    to={id + '-question'}
                    onClick={expand}
                    offset={-140}
                    smooth={true}
                    duration={500}
                    data-id={id}
                    isDynamic={true}
                >
                    <Button data-id={id} active={active}>
                        <img src={`/${active ? 'arrow_up_white.svg' : 'arrow_down_white.svg'}`} width="18" height="9" />
                    </Button>
                </ScrollLink>
            </QuestionWrapper>
            {!isMobile &&
                <AnswerWrapper active={active}>
                    <SingleAnswer>
                        <ImageWrapper>
                            <Img src={image} alt={alt} />
                            <div>
                                <Typography
                                    variant="questionsName"
                                    fontSize="23px"
                                    color="text.dark">
                                    {getFirstName(name)}
                                </Typography>
                                <Typography
                                    variant="questionsName"
                                    fontSize="36px"
                                    color="neutral.ultraLight"
                                    paddingBottom="20px">
                                    {getLastName(name)}
                                </Typography>
                            </div>
                        </ImageWrapper>
                        {audio && (
                            <Audio track={audio} specialColor={"#23191C"} />
                        )}
                        <Typography
                            variant="bodySection"
                            color="text.primary"
                            fontSize={isMobile ? "14px" : "16px"}
                            fontWeight={isMobile ? 400 : 500}
                            dangerouslySetInnerHTML={{ __html: content }}>
                        </Typography>
                    </SingleAnswer>
                </AnswerWrapper>
            }
        </FlexContainer>
    )
}

export default SingleQuestionComponent
