import React from "react"
import SingleQuestionComponent from "./SingleQuestionComponent"
import { Question } from "../../../interfaces/Page"
import { QuestionsContainer } from "./styles"

type Props = {
  questions: Question[]
}

const Questions: React.FC<Props> = ({ questions }): JSX.Element => {
  ////console.log(questions)

  return (
    <QuestionsContainer>
      {questions?.map((q, i) => {
        return (
          <SingleQuestionComponent
            key={i}
            id={i}
            question={q.question}
            image={q.image.url}
            alt={q.image.alt}
            name={q.name}
            audio={q.audio}
            content={q.content}
          />
        )
      })}
    </QuestionsContainer>
  )
}

export default Questions
