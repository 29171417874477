import styled from "styled-components"

export const QuestionsContainer = styled.ul`
  width: 88%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`
export const FlexContainer = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 0;
  }
`
export const QuestionMark = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%,-50%);

  @media (max-width: 768px) {
    top: 0;
    left: 50%;
  }
`
export const SingleQuestion = styled.div`
  padding: 0 38px;
  height: 94px;
  width: 680px;
  border-radius: 5px;
  border: 1px solid rgba(242,238,237,0.37);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%; 
    padding: 30px;
    text-align: center;
    margin-bottom: 5px;
  }
`
export const Button = styled.div`
  width: 100px;
  height: 94px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  transition: 0.2s;
  cursor: pointer;
  background-color: ${({ active }) => active ? 'transparent' : '#df4a32'};
  border-color: ${({ active }) => active ? 'rgba(242,238,237,0.37)' : 'transparent'};
  box-shadow: ${({ active }) => active ? 'none' : '0 0 6px #df4a32'};

  @media (max-width: 768px) {
    width: 100%;
    height: 50px;
    background-color: #df4a32;
    border: none;
    box-shadow: 0 0 6px #df4a32;
    border-radius: ${({ active }) => active ? "0 0 5px 5px" : "5px"};
  }
`

export const AnswerWrapper = styled.div`
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
  max-height:  ${({ active }) => active ? "2000px" : 0};

  @media (max-width: 768px) {
    box-shadow: 0 0 6px #df4a32;
  }
`
export const SingleAnswer = styled.div`
  padding: 38px 60px 50px 60px;
  height: 100%;
  background: #df4a32;
  box-shadow: 0 0 7px #df4a32;
  border-radius: 5px;
  margin-bottom: 18px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 31px 18px;
    margin-bottom: 0;
    border-radius: 5px 5px 0 0;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  margin-bottom: 27px;
  align-items: center;
`
export const Img = styled.img`
  width: 115px;
  height: 115px;
  border-radius: 50%;
  filter: grayscale(100%);
  margin-right: 23px;
`